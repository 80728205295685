import { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContextProvider";
import { LoginBanner } from "./LoginBanner";
import { ProductCard } from "./ProductCard";
import ProductImage from "../assets/images/product.png";
import { Manual } from "./ManualCard";
import ManualsContainer from "./ManualsContainer";
import { ActionTypes } from "../actions/actions";
import { useTranslation } from "react-i18next";

const image = "/images/manual.png";

const brochure: Manual = {
    title: "Manuale di installazione, uso e manutenzione SOLARsplit R32",
    category: "brochures",
    model: "SOLARsplit",
    date: "12/12/2024",
    revision: "R03",
    languages: ["IT"],
    extension: ".PDF",
    imageSrc: image,
    manualLink: "https://google.it",
    downloadLink: "https://google.it"
}

const manual: Manual = {
    title: "Manuale di installazione, uso e manutenzione SOLARsplit R32",
    category: "manuali_uso",
    model: "SOLARsplit",
    date: "12/12/2024",
    revision: "R03",
    languages: ["IT"],
    extension: ".PDF",
    imageSrc: image,
    manualLink: "https://google.it",
    downloadLink: "https://google.it"
}

const manuals = [manual, brochure, brochure];

export default function Home() {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        dispatch({
            type: ActionTypes.LoadManuals,
            payload: manuals
        })
    }, [])

    return (
        <div className="flex flex-col pt-8 md:pt-10 gap-6 md:gap-8 items-center">
            <LoginBanner />
            <ProductCard
                title="ECOhybrid Max"
                serial="24270900"
                modelNumber="1G18000008"
                imageSrc={ProductImage}
            />
            {state.manuals.length > 0 ?
                <ManualsContainer />
                :
                <div>
                    {t("manuals.noManuals")}
                </div>
            }
        </div>
    );
}