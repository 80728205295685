import { Category } from "../enums/enums";
import { Manual } from "../components/ManualCard";

export interface AppState {
    category: string | null,
    manuals: Manual[]
}

export const initialAppState: AppState = {
    category: null,
    manuals: []
}