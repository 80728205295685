import { t } from "i18next";
import { Category } from "../enums/enums";
import { useTranslation } from "react-i18next";

export interface Manual {
    title?: string,
    category: string,
    model?: string,
    manualLink?: string,
    downloadLink?: string,
    imageSrc?: string,
    date?: string,
    revision?: string,
    languages?: string[],
    extension?: string,
}

export function ManualCard(props: Manual) {
    const { t } = useTranslation();
    const { title, category, model, imageSrc, manualLink, downloadLink, date, revision, languages, extension } = props;
    return (
        <div className="flex gap-4 border justify-between bg-white p-4 rounded-lg border-gray-200 md:w-[90%]">
            <div className="flex items-center w-[20%]">
                <img className="w-full" src={imageSrc} />
            </div>
            <div className="flex flex-col items-start justify-center w-[80%] gap-3">
                {category &&
                    <div className="px-2 py-1 text-[12px] rounded-[16px] bg-gray-blue-50 border border-l-gray-blue-200">
                        {t(`manuals.categories.${category}`)}
                    </div>
                }

                <a href={manualLink} className="text-primary-600 font-semibold text-left">{title}</a>
                <div className="text-gray-700 text-[12px]">
                    {model}
                </div>
                <div className="flex gap-3 justify-between items-center w-full">
                    <div className="flex gap-3 text-gray-400">
                        <span>{date}</span>
                        <span>{revision}</span>
                        <span>{languages}</span>
                        <span>{extension}</span>
                    </div>
                    <a href={downloadLink}>
                        <img src="/download.svg" />
                    </a>
                </div>
            </div>
        </div>
    )
}