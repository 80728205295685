import { useContext } from "react";
import { Categories } from "./Categories";
import { AppContext } from "../context/AppContextProvider";
import { ManualCard } from "./ManualCard";

export default function ManualsContainer() {
    const { state } = useContext(AppContext)
    const { manuals, category } = state;
    console.log(category)
    const filteredManuals = manuals.filter(manual => manual.category === category);
    console.log(filteredManuals)
    return (
        <>
            <Categories />
            {
                filteredManuals.map(manual => {
                    return (
                        <ManualCard
                            key={manual.title}
                            {...manual}
                        />
                    );
                })
            }
        </>
    )
}