import { useContext, useState } from "react"
import Logo from "./Logo"
import { AppContext } from "../context/AppContextProvider";
import LangSwitcher from "./LangSwitcher";
import Footer from "./Footer";

export default function Container(props: { children: JSX.Element }) {
    const { state } = useContext(AppContext);
    return <>
        <div className="wrapper">
            {
                process.env.REACT_APP_ENV === "dev" &&
                <div className="hidden md:block absolute left-[5%] md:left-[10%] md:pt-[106px] z-10">
                    <LangSwitcher />
                </div>
            }
            <Logo />
            <main className="content px-6 md:px-4 pb-[130px] md:pb-[100px]">
                <div className="container">
                    {props.children}
                </div>
            </main >
            <Footer />
        </div >
    </>
}