import { Trans } from "react-i18next";
import { t } from "i18next";

interface ProductCardProps {
    title: string,
    serial: string,
    modelNumber: string,
    imageSrc: string,
}

export function ProductCard(props: ProductCardProps) {
    const { title, serial, modelNumber, imageSrc } = props;
    return (
        <div className="flex flex-col md:flex-row gap-10 border justify-between bg-white p-8 md:px-12 md:py-8 rounded-lg border-gray-200 w-full">
            <div className="flex justify-center">
                <img className="md:w-full" src={imageSrc} />
            </div>
            <div className="flex flex-col items-start justify-center w-full md:max-w-[70%]">
                <h1 className="text-gray-900 font-bold">{title}</h1>
                <h2 className="text-gray-900 text-[24px]">{serial}</h2>
                <div className="flex gap-2 text-gray-400 pt-2 text-[18px]">
                    <h3 className="font-bold">{t("modelNumber")}</h3>
                    <h3>{modelNumber}</h3>
                </div>
                <a className="product-link mt-5 text-nowrap" href="/">
                    {t("buttonLabel")}
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.83331 14.1666L14.1666 5.83325M14.1666 5.83325H5.83331M14.1666 5.83325V14.1666" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </a>
                <p className="text-gray-400">{t("underButtonLabel")}</p>
            </div>
        </div>
    )
}