import LogoImage from "../assets/images/logo.png";
import LangSwitcher from "./LangSwitcher";

export default function Logo() {
    return (
        <div className="logo flex justify-center px-6 md:px-4 pt-[58px] md:pt-[96px]">
            {
                process.env.REACT_APP_ENV === "dev" &&
                <div className="md:hidden">
                    <LangSwitcher />
                </div>
            }
            <img src={LogoImage} alt="Cosmogas" className="w-1/2 md:w-[240px]" />
        </div>
    )
}